import classes from "./GoldTicker.module.css";

const GoldTicker = () => {
  return (
    <section className={classes.live}>
      <h2>Live Forex prices</h2>
      <iframe
        src="//www.exchangerates.org.uk/widget/ER-LRTICKER.php?w=2000&s=3&mc=USD&mbg=F0F0F0&bs=yes&bc=000044&f=verdana&fs=12px&fc=000044&lc=000044&lhc=FE9A00&vc=FE9A00&vcu=008000&vcd=FF0000&"
        height="30"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        title="currency"
        width="100%"
      ></iframe>
      <h2>Live Crypto prices</h2>
      <iframe
        src="/cryptoWidget.html"
        width="100%"
        height="80"
        style={{ border: "none" }}
        title="Crypto Widget"
      ></iframe>
      <h2>Live Gold prices</h2>
      <iframe
        title="Live gold price widget"
        src="https://goldbroker.com/widget/live/XAU?currency=USD&height=320"
        scrolling="no"
        frameBorder="0"
        width="100%"
        height="320"
        style={{ border: "0", overflow: "hidden" }}
      ></iframe>
      <a href="https://goldeilen.com">GO BACK HOME</a>
    </section>
  );
};

export default GoldTicker;
